import React, { useState, useEffect } from 'react';
import { Box, Typography, Card, CardContent, IconButton, Collapse, Divider } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import { useParams } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import { format } from 'date-fns';
import { ptBR } from 'date-fns/locale';
import PatientDetailsPage from './PatientDetailsPage';

interface Appointment {
  id: string;
  date: Date;
  status: string;
  model: { name: string };
  professional: { name: string };
  duration: number;
  notes: string;
  steps: { id: string; clinicalStep: { title: string }; value: string }[];
}

const AppointmentTimeline: React.FC = () => {
  const { t } = useTranslation();
  const [appointments, setAppointments] = useState<Appointment[]>([]);
  const [expandedId, setExpandedId] = useState<string | null>(null);
  const { patientId } = useParams<{ patientId: string }>();

  useEffect(() => {
    const fetchAppointments = async () => {
      try {
        const response = await api.get(`/api/appointments/details/all/${patientId}`);
        setAppointments(response.data);
      } catch (error) {
        console.error(t('error_fetching_appointments'), error);
      }
    };

    fetchAppointments();
  }, [t, patientId]);

  const formatDate = (date: Date) => {
    return format(new Date(date), "dd MMM yyyy, HH:mm", { locale: ptBR });
  };

  const handleToggleExpand = (id: string) => {
    setExpandedId((prev) => (prev === id ? null : id));
  };

  const color: { [key: string]: string } = {
    scheduled: 'info.main',
    open: 'primary.main',
    closed: 'secondary.main',
    canceled: 'error.main',
  };

  return (
    <PatientDetailsPage>
      {appointments.map((appointment) => {
        const isExpanded = expandedId === appointment.id;

        return (
          <Card
            key={appointment.id}
            sx={{
              marginBottom: 2,
              borderRadius: 2,
              boxShadow: 3,
              backgroundColor: '#f9f9f9',
              transition: 'background-color 0.3s',
              '&:hover': {
                backgroundColor: '#f1f1f1',
              },
            }}
          >
            <CardContent sx={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', padding: 2 }}>
              <Box>
                <Typography variant="h6" sx={{ fontWeight: 'bold', color: color[appointment.status] }}>
                  {appointment.model?.name || t('appointment')}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 0.5 }}>
                  {formatDate(appointment.date)}
                </Typography>
                <Typography variant="body2" color="textSecondary" sx={{ marginTop: 0.5 }}>
                  {t('by')}: {appointment.professional.name}
                </Typography>
              </Box>
              <IconButton onClick={() => handleToggleExpand(appointment.id)} sx={{ color: color[appointment.status] }}>
                {isExpanded ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </CardContent>
            <Collapse in={isExpanded} timeout="auto" unmountOnExit>
              <Divider />
              <CardContent sx={{ padding: 2 }}>
                {appointment.status === 'closed' && (
                  <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
                    {`${t('time_spent')}: ${appointment.duration} ${t('minutes')}`}
                  </Typography>
                )}
                {appointment.status === 'scheduled' && appointment.notes && (
                  <Typography variant="body2" color="textSecondary" sx={{ marginBottom: 1 }}>
                    {`${t('notes')}: ${appointment.notes}`}
                  </Typography>
                )}
                {appointment.steps.map((step) => (
                  <Box key={step.id} sx={{ marginBottom: 2 }}>
                    <Typography variant="body2" sx={{ fontWeight: 'bold', color: 'text.primary', marginBottom: 1 }}>
                      {step.clinicalStep.title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="textSecondary"
                      dangerouslySetInnerHTML={{ __html: step.value }}
                      sx={{
                        marginLeft: 2,
                        marginTop: 1,
                        whiteSpace: 'pre-wrap', 
                      }}
                    />
                  </Box>
                ))}
              </CardContent>
            </Collapse>
          </Card>
        );
      })}
    </PatientDetailsPage>
  );
};

export default AppointmentTimeline;
