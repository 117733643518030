import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import MaterialForm from './MaterialForm';
import CustomTable from '../../../components/common/CustomTable';
import { CreateMaterialDto, UpdateMaterialDto } from '../services/dto/material.dto';


interface Material {
  id: number;
  name: string;
  description: string;
  quantityInStock: number;
  reorderPoint: number;
}

const MaterialPage: React.FC = () => {
  const { t } = useTranslation();
  const [materials, setMaterials] = useState<Material[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedMaterial, setSelectedMaterial] = useState<Material | null>(null);

  useEffect(() => {
    const fetchMaterials = async () => {
      try {
        const response = await api.get('/api/materials');
        setMaterials(response.data);
      } catch (error) {
        console.error(t('error_fetching_materials'), error);
      }
    };

    fetchMaterials();
  }, [t]);

  const handleEdit = (material: Material) => {
    setSelectedMaterial(material);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedMaterial(null);
    setOpenModal(true);
  };

  const handleDelete = async (id: number) => {
    try {
      await api.delete(`/api/materials/${id}`);
      setMaterials(materials.filter(material => material.id !== id));
    } catch (error) {
      console.error(t('error_deleting_material'), error);
    }
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedMaterial(null);
    if (updated) {
      try {
        const response = await api.get('/api/materials');
        setMaterials(response.data);
      } catch (error) {
        console.error(t('error_fetching_materials'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('materials')}
        columns={[
          { id: 'name', label: t('name') },
          { id: 'unitPrice', label: t('unit_price') },
          { id: 'quantityInStock', label: t('quantity_in_stock') },
          { id: 'reorderPoint', label: t('reorder_point') },
          { id: 'category', label: t('category'), isTabFilter: true },
        ]}
        data={materials}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleDelete}
        searchPlaceholder={t('search_materials')}
      />
      <MaterialForm open={openModal} onClose={handleCloseModal} material={selectedMaterial} />
    </>
  );
};

export default MaterialPage;
