import React, { useState } from 'react';
import { Typography, Grid, Box, Avatar, Divider, Tooltip, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import PatientForm from './PatientForm';

interface PatientSummaryProps {
  patient: any;
  onUpdate: (updated: boolean) => void;
}

const renderSummaryItem = (label: string, value: string) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="body2" fontWeight="bold">
      <strong>{label}:</strong>
    </Typography>
    <Tooltip title={value}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  </Grid>
);

const PatientSummary: React.FC<PatientSummaryProps> = ({ patient, onUpdate }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);
  const [selectedPatient, setSelectedPatient] = useState<any>(null);

  const handleOpenModal = () => {
    setSelectedPatient(patient);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedPatient(null);

    onUpdate(updated);
  };

  return (
    <Grid item xs={12} md={6}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t('patientSummary')}</Typography>
        <IconButton onClick={handleOpenModal} sx={{ ml: 1 }}>
          <EditIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box display="flex" alignItems="center" mb={1}>
        <Avatar
          alt={patient?.name || 'Avatar'}
          src="https://via.placeholder.com/150"
          sx={{ width: 80, height: 80, mr: 2 }}
        />
        <Box>
          <Grid container spacing={1}>
            {renderSummaryItem(t('name'), patient?.name || '')}
            {renderSummaryItem(t('birthDate'), patient?.birthDate ? new Date(patient.birthDate).toLocaleDateString() : '')}
            {renderSummaryItem(t('gender'), t(patient?.gender) || '')}
            {renderSummaryItem(t('email'), patient?.email || '')}
            {renderSummaryItem(t('contact'), patient?.contact || '')}
            {renderSummaryItem(t('notes'), patient?.notes || '')}
          </Grid>
        </Box>
      </Box>

      <PatientForm
        open={openModal}
        onClose={handleCloseModal}
        patient={selectedPatient}
      />
    </Grid>
  );
};

export default PatientSummary;
