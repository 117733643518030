import React, { useState } from 'react';
import { Typography, Grid, Box, Divider, Tooltip, IconButton } from '@mui/material';
import { Edit as EditIcon } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import CustomForm from '../../../components/common/CustomForm';
import api from '../../../services/api';

interface MedicalHistoryProps {
  patient: any;
  formValues: any;
  onUpdate: (updated: boolean) => void;
}

const renderSummaryItem = (label: string, value: string) => (
  <Grid item xs={12} sm={6}>
    <Typography variant="body2" fontWeight="bold">
      <strong>{label}:</strong>
    </Typography>
    <Tooltip title={value}>
      <Typography
        variant="body2"
        color="textSecondary"
        sx={{
          whiteSpace: 'nowrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
        }}
      >
        {value}
      </Typography>
    </Tooltip>
  </Grid>
);

const MedicalHistory: React.FC<MedicalHistoryProps> = ({ patient, formValues, onUpdate }) => {
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState(false);

  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const handleCloseModal = async (updated = false) => {
    setOpenModal(false);
    onUpdate(updated);
  };

  return (
    <Grid item xs={12} md={6}>
      <Box display="flex" alignItems="center" justifyContent="space-between">
        <Typography variant="h6">{t('medical_history')}</Typography>
        <IconButton onClick={handleOpenModal} sx={{ ml: 1 }}>
          <EditIcon />
        </IconButton>
      </Box>
      <Divider sx={{ mb: 1 }} />
      <Box>
        <Grid container spacing={1}>
          {renderSummaryItem(t('clinical_history'), formValues.clinicalHistory)}
          {renderSummaryItem(t('surgical_history'), formValues.surgicalHistory)}
          {renderSummaryItem(t('family_history'), formValues.familyHistory)}
          {renderSummaryItem(t('habits'), formValues.habits)}
          {renderSummaryItem(t('allergies'), formValues.allergies)}
          {renderSummaryItem(t('medications'), formValues.medications)}
        </Grid>
      </Box>

      <CustomForm
        open={openModal}
        onClose={handleCloseModal}
        title={t('edit_medical_history')}
        fields={[
          { id: 'clinicalHistory', label: t('clinical_history'), type: 'text', sm: 6 },
          { id: 'surgicalHistory', label: t('surgical_history'), type: 'text', sm: 6 },
          { id: 'familyHistory', label: t('family_history'), type: 'text', sm: 6 },
          { id: 'habits', label: t('habits'), type: 'text', sm: 6 },
          { id: 'allergies', label: t('allergies'), type: 'text', sm: 6 },
          { id: 'medications', label: t('medications'), type: 'text', sm: 6 },
        ]}
        data={{
          clinicalHistory: formValues.clinicalHistory,
          surgicalHistory: formValues.surgicalHistory,
          familyHistory: formValues.familyHistory,
          habits: formValues.habits,
          allergies: formValues.allergies,
          medications: formValues.medications,
        }}
        onSubmit={async (data) => {
          const updatedPatient = { ...patient, ...data };
          try {
            await api.put(`/api/patients/${patient.id}`, updatedPatient);
            handleCloseModal(true);
          } catch (error) {
            console.error(t('error_updating_patient'), error);
          }
        }}
      />
    </Grid>
  );
};

export default MedicalHistory;
