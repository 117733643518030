import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import CustomTable from '../../../components/common/CustomTable';
import ItemForm from './AppointmentStepForm';

interface Item {
  id: number;
  modelId: number;
  value: string;
}

const ItemPage: React.FC = () => {
  const { t } = useTranslation();
  const [item, setItem] = useState<Item[]>([]);
  const [openModal, setOpenModal] = useState(false);
  const [selectedItem, setSelectedItem] = useState<Item | null>(null);

  useEffect(() => {
    const fetchItem = async () => {
      try {
        const response = await api.get('/api/model-items');
        setItem(response.data);
      } catch (error) {
        console.error(t('error_fetching_items'), error);
      }
    };

    fetchItem();
  }, [t]);

  const handleEdit = (item: Item) => {
    setSelectedItem(item);
    setOpenModal(true);
  };

  const handleAdd = () => {
    setSelectedItem(null);
    setOpenModal(true);
  };

  const handleCloseModal = async (updated: boolean = false) => {
    setOpenModal(false);
    setSelectedItem(null);
    if (updated) {
      try {
        const response = await api.get('/api/model-items');
        setItem(response.data);
      } catch (error) {
        console.error(t('error_fetching_items'), error);
      }
    }
  };

  return (
    <>
      <CustomTable
        title={t('items')}
        columns={[
          { id: 'value', label: t('value') },
          { id: 'modelId', label: t('modelId') },
        ]}
        data={item}
        onAdd={handleAdd}
        onEdit={handleEdit}
        onDelete={handleEdit}
        searchPlaceholder={t('search_item')}
      />
      <ItemForm open={openModal} onClose={handleCloseModal} item={selectedItem} />
    </>
  );
};

export default ItemPage;
