import React, { useState, useEffect } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  MenuItem,
  Grid,
  Divider,
  FormControl,
  InputLabel,
  InputAdornment,
  Select,
  Checkbox,
  ListItemText,
  Autocomplete,
} from '@mui/material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import { useForm, Controller } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { useMask } from '@react-input/mask';

export interface Field {
  id: string;
  label: string;
  type: 'text' | 'number' | 'email' | 'password' | 'select' | 'multiselect' | 'autocomplete' | 'masked' | 'date' | 'datetime-local' | 'color' | 'textarea' | 'phone' | 'cep' | 'cpf';
  required?: boolean;
  options?: { label: string, value: string }[];
  sm?: number;
  width?: number;
  multiple?: boolean;
  format?: 'currency' | 'minutes' | 'percentage' | 'phone' | 'datetime';
  validation?: yup.AnySchema;
  readOnly?: boolean;
  onChange?: (value: string, setValue: (field: string, value: any, shouldValidate?: boolean) => void) => void;
}

interface CustomFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  title: string;
  fields: Field[];
  data?: any;
  onSubmit: (data: any) => Promise<void>;
  fullScrean?: boolean;
}

const CustomForm: React.FC<CustomFormProps> = ({ open, onClose, title, fields, data, onSubmit, fullScrean = false }) => {
  const { t } = useTranslation();
  const theme = useTheme();

  const phoneMask = useMask({ mask: '(__) _____-____', replacement: '_' });
  const cepMask = useMask({ mask: '_____-___', replacement: '_' });
  const cpfMask = useMask({ mask: '___.___.___-__', replacement: '_' });

  const validationSchema = yup.object().shape(
    fields.reduce((acc, field) => {
      if (field.validation) {
        acc[field.id] = field.validation;
      } else {
        let validator = yup.string();
        if (field.required) {
          validator = validator.required(`${field.label} é obrigatório`);
        }
        acc[field.id] = validator;
      }
      return acc;
    }, {} as { [key: string]: yup.AnySchema })
  );

  const { control, handleSubmit, formState: { errors }, reset, setValue } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: data || {},  // Garantir que os dados sejam passados corretamente aqui
  });

  useEffect(() => {
    if(errors.length) {
      console.error(errors)
    }
  }, [errors]);

  useEffect(() => {
    reset(data);  // Atualizar o formulário com os dados ao editar
  }, [data, reset]);

  const handleSubmitForm = async (formData: any) => {
    try {
      await onSubmit(formData);
      reset();
      onClose(true);
    } catch (error) {
      console.error('Erro ao salvar dados:', error);
      onClose(false);
    }
  };

  const renderTextField = (field: Field) => {
    let mask: any = null;

    switch (field.type) {
      case 'phone':
        mask = phoneMask;
        break;
      case 'cep':
        mask = cepMask;
        break;
      case 'cpf':
        mask = cpfMask;
        break;
      default:
        break;
    }

    const isDateField = field.type === 'date';
    const isColorField = field.type === 'color';

    return (
      <Grid item xs={12} sm={field.sm || 12} key={field.id}>
        <Controller
          name={field.id}
          control={control}
          render={({ field: controllerField }) => (
              <TextField
                {...controllerField}
                label={field.label}
                variant="outlined"
                type={isDateField ? 'date' : isColorField ? 'color' : 'text'}
                error={!!errors[field.id]}
                helperText={errors[field.id]?.message as React.ReactNode}
                fullWidth
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  readOnly: field.readOnly,
                  startAdornment: field.format === 'currency' ? <InputAdornment position="start">R$</InputAdornment> : undefined,
                  endAdornment: field.format === 'minutes' ? <InputAdornment position="end">min</InputAdornment> : undefined,
                }}
                onChange={(e) => {
                  controllerField.onChange(e);
                  field.onChange && field.onChange(e.target.value, (fieldId, value) => setValue(fieldId as any, value));
                }}
              />
          )}
        />
      </Grid>
    );
  };

  const renderSelectField = (field: Field) => (
    <Grid item xs={12} sm={field.sm || 12} key={field.id}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: controllerField }) => (
          <TextField
            {...controllerField}
            select
            label={field.label}
            variant="outlined"
            error={!!errors[field.id]}
            helperText={errors[field.id]?.message as React.ReactNode}
            fullWidth
            InputProps={{ readOnly: field.readOnly }}
            onChange={(e) => {
              controllerField.onChange(e);
              field.onChange && field.onChange(e.target.value, (fieldId, value) => setValue(fieldId as any, value));
            }}
          >
            {field.options?.map((option) => (
              <MenuItem key={option.value} value={option.value}>
                {option.label}
              </MenuItem>
            ))}
          </TextField>
        )}
      />
    </Grid>
  );

  const renderMultiSelectField = (field: Field) => (
    <Grid item xs={12} sm={field.sm || 12} key={field.id}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: controllerField }) => (
          <FormControl fullWidth required={field.required} variant="outlined">
            <InputLabel>{field.label}</InputLabel>
            <Select
              {...controllerField}
              multiple
              renderValue={(selected) =>
                (selected as string[]).map(value => field.options?.find(option => option.value === value)?.label).join(', ')
              }
              inputProps={{ readOnly: field.readOnly }}
              onChange={(e) => {
                controllerField.onChange(e);
                field.onChange && field.onChange(e.target.value as string, (fieldId, value) => setValue(fieldId as any, value));
              }}
            >
              {field.options?.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                  <Checkbox checked={(controllerField.value as string[]).indexOf(option.value) > -1} />
                  <ListItemText primary={option.label} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      />
    </Grid>
  );

  const renderAutocompleteField = (field: Field) => (
    <Grid item xs={12} sm={field.sm || 12} key={field.id}>
      <Controller
        name={field.id}
        control={control}
        render={({ field: controllerField }) => (
          <Autocomplete
            multiple={field.multiple}
            options={field.options || []}
            getOptionLabel={(option) => option.label}
            value={controllerField.value || (field.multiple ? [] : null)}
            onChange={(event, value) => {
              controllerField.onChange(value);
              field.onChange && field.onChange(value as string, (fieldId, value) => setValue(fieldId as any, value));
            }}
            renderInput={(params) => (
              <TextField
                {...params}
                label={field.label}
                variant="outlined"
                error={!!errors[field.id]}
                helperText={errors[field.id]?.message as React.ReactNode}
                fullWidth
              />
            )}
          />
        )}
      />
    </Grid>
  );

  const renderField = (field: Field) => {
    switch (field.type) {
      case 'select':
        return renderSelectField(field);
      case 'multiselect':
        return renderMultiSelectField(field);
      case 'autocomplete':
        return renderAutocompleteField(field);
      default:
        return renderTextField(field);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth fullScreen={fullScrean}>
      <DialogTitle>{title}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={(e) => {
          e.preventDefault();
          console.log('Submit disparado'); // Log ao disparar o submit
          handleSubmit(handleSubmitForm)();
        }}>
          <Grid container spacing={1.5}>
            {fields.map(renderField)}
          </Grid>
          <DialogActions sx={{ pb: 0, pt: 2 }}>
            <Button onClick={() => onClose(false)} sx={{ color: theme.palette.text.primary }}>
              {t('cancel')}
            </Button>
            <Button type="submit" color="primary" variant="contained">
              {data ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default CustomForm;
