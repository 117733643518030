import React from 'react';
import { Drawer, List, ListItemButton, ListItemIcon, ListItemText, Divider, Typography } from '@mui/material';
import { FaHome, FaBuilding, FaUsers, FaUserCircle, FaCog, FaCalendarAlt, FaBoxes, FaBox, FaFileMedical } from 'react-icons/fa';
import { styled, useTheme, alpha } from '@mui/material/styles';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';


interface SidebarProps {
  open: boolean;
  toggleDrawer: () => void;
}

const drawerWidth = 200;
const minimizedWidth = 64;

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
  backgroundColor: 'transparent',
  transition: 'padding 0.3s ease',
}));

const GradientIcon: React.FC<{ Icon: React.ElementType }> = ({ Icon }) => (
  <Icon size="1.5em" style={{ fill: 'url(#gradient1)' }} />
);

const SidebarItem: React.FC<{ path: string, label: string, Icon: React.ElementType, open: boolean, selected: boolean }> = ({ path, label, Icon, open, selected }) => {
  const theme = useTheme();

  return (
    <ListItemButton
      component={RouterLink}
      to={path}
      selected={selected}
      sx={{
        typography: 'body2',
        color: theme.palette.grey[500],
        '&:hover': {
          color: theme.palette.primary.main,
          bgcolor: alpha(theme.palette.primary.main, 0.08),
          '& .MuiListItemIcon-root': {
            color: theme.palette.primary.main,
          },
        },
        ...(selected && {
          color: theme.palette.primary.main,
          bgcolor: alpha(theme.palette.primary.main, 0.15),
        }),
      }}
    >
      <ListItemIcon sx={{ minWidth: '40px' }}>
        <GradientIcon Icon={Icon} />
      </ListItemIcon>
      <ListItemText
        primary={label}
        sx={{
          opacity: open ? 1 : 0,
          transition: 'opacity 0.3s ease',
        }}
      />
    </ListItemButton>
  );
};

const Sidebar: React.FC<SidebarProps> = ({ open, toggleDrawer }) => {
  const theme = useTheme();
  const location = useLocation();
  const selectedPath = location.pathname;
  const { t } = useTranslation()

  return (
    <Drawer
      variant="permanent"
      sx={{
        width: open ? drawerWidth : minimizedWidth,
        flexShrink: 0,
        transition: 'width 0.3s ease',
        '& .MuiDrawer-paper': {
          width: open ? drawerWidth : minimizedWidth,
          transition: 'width 0.3s ease',
          backgroundColor: theme.sidebar.backgroundColor,
          color: '#FFFFFF',
          overflowX: 'hidden',
          backgroundImage: 'url(/assets/cyan-blur.png), url(/assets/red-blur.png)',
          backgroundRepeat: 'no-repeat, no-repeat',
          backgroundPosition: 'top right, left bottom',
          backgroundSize: '100%, 100%',
        },
      }}
    >
      <DrawerHeader>
        <img
          src="/logo.svg"
          alt="MY CLINICAL Logo"
          style={{ height: '40px', transition: 'margin 0.3s ease' }}
        />
        {open && (
          <Typography
            variant="h5"
            noWrap
            component="div"
            sx={{
              transition: 'opacity 0.3s ease, transform 0.3s ease',
              opacity: open ? 1 : 0,
              transform: open ? 'translateX(0)' : 'translateX(-50%)',      
              fontFamily: '"Roboto", sans-serif',
              position: 'relative',
              top: '0.175em',
            }}
          >
            clinical
          </Typography>
        )}
      </DrawerHeader>

      <Divider />
      <List>
        <SidebarItem path="/dashboard" label={t('Dashboard')} Icon={FaHome} open={open} selected={selectedPath === '/dashboard'} />
        <SidebarItem path="/companies" label={t('Companies')} Icon={FaBuilding} open={open} selected={selectedPath === '/companies'} />
        <SidebarItem path="/users" label={t('Users')} Icon={FaUsers} open={open} selected={selectedPath === '/users'} />
        <SidebarItem path="/patients" label={t('Patients')} Icon={FaUserCircle} open={open} selected={selectedPath === '/patients'} />
        <SidebarItem path="/models" label={t('Models')} Icon={FaCog} open={open} selected={selectedPath === '/models'} />
        <SidebarItem path="/calendar" label={t('Calendar')} Icon={FaCalendarAlt} open={open} selected={selectedPath === '/calendar'} />
        <SidebarItem path="/materials" label={t('Materials')} Icon={FaBox} open={open} selected={selectedPath === '/materials'} />
        <SidebarItem path="/procedures" label={t('Procedures')} Icon={FaFileMedical} open={open} selected={selectedPath === '/procedures'} />
      </List>
      <svg width="0" height="0">
        <defs>
          <linearGradient id="gradient1" x1="0%" y1="0%" x2="100%">
            <stop offset="0%" style={{ stopColor: '#637381', stopOpacity: 1 }} />
            <stop offset="100%" style={{ stopColor: '#a0a0a0', stopOpacity: 1 }} />
          </linearGradient>
        </defs>
      </svg>
    </Drawer>
  );
};

export default Sidebar;
