import React, { useState, useEffect } from 'react';
import { Box, Button, Stepper, Step, StepLabel, StepContent, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import { useNavigate } from 'react-router-dom';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

interface AppointmentStepsProps {
    appointmentId: number;
    patientId: number;
    appointmentModelId: number;
}

const modules = {
    toolbar: [
      [{ 'header': '3' }, { 'header': false }],  // Only Heading 3 and Normal
      ['bold', 'italic', 'underline'],  // Other formatting options
      [{ 'list': 'ordered'}, { 'list': 'bullet' }],
      ['link', 'image'],
    ],
  };

// const formats = [
//     'header', 'bold', 'italic', 'underline', 'list', 'bullet', 'link', 'image'
// ];

const AppointmentSteps: React.FC<AppointmentStepsProps> = ({ appointmentId, patientId, appointmentModelId }) => {
    const { t } = useTranslation();
    const [clinicalSteps, setClinicalSteps] = useState<{ id: number, title: string, order: number }[]>([]);
    const [activeStep, setActiveStep] = useState(0);
    const [formValues, setFormValues] = useState<{ [key: string]: any }>({});
    const [savedStepIds, setSavedStepIds] = useState<{ [key: string]: number }>({});
    const navigate = useNavigate();

    useEffect(() => {
        const fetchClinicalSteps = async () => {
            try {
                const response = await api.get(`/api/appointment-models/${appointmentModelId}`);
                const stepsData = response.data.clinicalSteps || [];
                setClinicalSteps(stepsData);

                const appointmentStepsResponse = await api.get(`/api/appointment-steps/appointment/${appointmentId}`);
                const appointmentSteps = appointmentStepsResponse.data;
                const updatedFormValues: { [key: string]: any } = {};
                const updatedSavedStepIds: { [key: string]: number } = {};

                appointmentSteps.forEach((step: any) => {
                    const clinicalStep = stepsData.find((s: any) => s.id === step.clinicalStepId);
                    if (clinicalStep) {
                        const stepKey = clinicalStep.title.replace(/ /g, '').toLowerCase();
                        updatedFormValues[stepKey] = step.value;
                        updatedSavedStepIds[stepKey] = step.id;
                    }
                });

                setFormValues(updatedFormValues);
                setSavedStepIds(updatedSavedStepIds);

                const lastFilledStepIndex = stepsData.findIndex(
                    (step: { title: string; }) => !updatedFormValues[step.title.replace(/ /g, '').toLowerCase()]
                );
                setActiveStep(lastFilledStepIndex === -1 ? stepsData.length - 1 : lastFilledStepIndex);
            } catch (error) {
                console.error('Error fetching clinical steps or appointment steps:', error);
            }
        };

        fetchClinicalSteps();
    }, [appointmentId, appointmentModelId]);

    const saveStep = async (index: number) => {
        const step = clinicalSteps[index];
        const stepKey = step.title.replace(/ /g, '').toLowerCase();
        const stepData = {
            appointmentId,
            clinicalStepId: step.id,
            value: formValues[stepKey],
        };

        try {
            if (savedStepIds[stepKey]) {
                await api.put(`/api/appointment-steps/${savedStepIds[stepKey]}`, stepData);
            } else {
                const response = await api.post('/api/appointment-steps', stepData);
                setSavedStepIds((prev) => ({
                    ...prev,
                    [stepKey]: response.data.id,
                }));
            }
        } catch (error) {
            console.error('Error saving step:', error);
        }
    };

    const handleNext = async () => {
        await saveStep(activeStep);
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    const handleStepClick = (step: number) => {
        setActiveStep(step);
    };

    const handleQuillChange = (name: string, value: string) => {
        setFormValues({ ...formValues, [name]: value });
    };

    const handleFinish = async () => {
        await saveStep(activeStep);
        try {
            await api.put(`/api/appointments/finalize/${appointmentId}`);
            navigate(`/timeline/${patientId}`);
        } catch (error) {
            console.error('Error finalizing appointment:', error);
        }
    };

    return (
        <Box>
            <Stepper activeStep={activeStep} orientation="vertical">
                {clinicalSteps.map((step, index) => {
                    const stepKey = step.title.replace(/ /g, '').toLowerCase();
                    return (
                        <Step key={step.order}>
                            <StepLabel
                                onClick={() => handleStepClick(index)}
                                sx={{ cursor: formValues[stepKey] ? 'pointer' : 'default' }}
                            >
                                <Typography variant="subtitle2" sx={{ fontWeight: 'bold' }}>
                                    {step.title}
                                </Typography>
                                {activeStep !== index && formValues[stepKey] && (
                                    <Typography
                                        variant="body2"
                                        sx={{
                                            color: 'text.secondary',
                                            marginLeft: 2,
                                            marginTop: 1,
                                            whiteSpace: 'pre-wrap',
                                        }}
                                        dangerouslySetInnerHTML={{ __html: formValues[stepKey] }}
                                    />
                                )}
                            </StepLabel>

                            <StepContent>
                                <ReactQuill
                                    theme="snow"
                                    value={formValues[stepKey] || ''}
                                    onChange={(value) => handleQuillChange(stepKey, value)}
                                    style={{ marginBottom: '16px' }}
                                    // modules={modules}  
                                    // formats={formats} 
                                />
                                <Box sx={{ mb: 1, mt: 1 }}>
                                    <div>
                                        <Button
                                            variant="contained"
                                            color="primary"
                                            onClick={index === clinicalSteps.length - 1 ? handleFinish : handleNext}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={!formValues[stepKey]}
                                        >
                                            {index === clinicalSteps.length - 1 ? t('finish') : t('next')}
                                        </Button>
                                        <Button
                                            onClick={handleBack}
                                            sx={{ mt: 1, mr: 1 }}
                                            disabled={index === 0}
                                        >
                                            {t('go_back')}
                                        </Button>
                                    </div>
                                </Box>
                            </StepContent>
                        </Step>
                    );
                })}
            </Stepper>
        </Box>
    );
};

export default AppointmentSteps;
