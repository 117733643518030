import React from 'react';
import { useTranslation } from 'react-i18next';
import api from '../../../services/api';
import CustomForm, { Field } from '../../../components/common/CustomForm';
import { CreatePatientDto, UpdatePatientDto } from '../services/dto/patient.dto';
import * as yup from 'yup';
import axios from 'axios';

interface PatientFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  patient?: CreatePatientDto & { id?: number } | UpdatePatientDto | null;
  onSave?: (savedPatient: any) => void; // Nova prop para retornar o paciente salvo
}

const PatientForm: React.FC<PatientFormProps> = ({ open, onClose, patient, onSave }) => {
  const { t } = useTranslation();

  const handleSubmit = async (formData: any) => {
    const formattedData = {
      ...formData,
      address: {
        cep: formData.cep,
        line1: formData.line1,
        number: formData.number,
        line2: formData.line2,
        city: formData.city,
        state: formData.state,
        country: formData.country,
      },
    };

    delete formattedData.cep;
    delete formattedData.line1;
    delete formattedData.number;
    delete formattedData.line2;
    delete formattedData.city;
    delete formattedData.state;
    delete formattedData.country;

    let savedPatient;

    if (patient && patient.id) {
      savedPatient = await api.put(`/api/patients/${patient.id}`, formattedData);
    } else {
      savedPatient = await api.post('/api/patients', formattedData);
    }

    onSave?.(savedPatient.data); // Retorna o paciente salvo para o componente pai
    onClose(true);
  };

  const handleCepChange = async (value: string, setValue: (field: string, value: any, shouldValidate?: boolean) => void) => {
    const cep = value.replace(/\D/g, '');

    if (cep.length === 8) {
      try {
        const response = await axios.get(`https://viacep.com.br/ws/${cep}/json/`);
        const { logradouro, localidade, uf } = response.data;
        setValue('line1', logradouro || '');
        setValue('city', localidade || '');
        setValue('state', uf || '');
        setValue('country', 'Brasil');
      } catch (error) {
        console.error('Erro ao buscar CEP:', error);
      }
    }
  };

  const fields: Field[] = [
    { id: 'name', label: t('name'), type: 'text', required: true, sm: 6, validation: yup.string().required(`${t('name')} ${t('is_required')}`) },
    { id: 'birthDate', label: t('birthDate'), type: 'date', required: true, sm: 6, validation: yup.date().required(`${t('birthDate')} ${t('is_required')}`).max(new Date(), t('birthDate_must_be_in_the_past')) },
    { id: 'gender', label: t('sex'), type: 'select', options: [ { label: t('male'), value: 'male' }, { label: t('female'), value: 'female' } ], required: true, sm: 6, validation: yup.string().required(`${t('sex')} ${t('is_required')}`) },
    { id: 'cpf', label: t('cpf'), type: 'cpf', required: true, sm: 6, validation: yup.string().required(`${t('cpf')} ${t('is_required')}`).matches(/^\d{3}\.\d{3}\.\d{3}-\d{2}$/, t('cpf_invalid')) },
    { id: 'rg', label: t('rg'), type: 'text', sm: 6 },
    { id: 'email', label: t('email'), type: 'text', sm: 6, validation: yup.string().required(`${t('email')} ${t('is_required')}`).email(t('email_invalid')) },
    { id: 'contact', label: t('contact'), type: 'phone', format: 'phone', required: true, sm: 6, validation: yup.string().required(`${t('contact')} ${t('is_required')}`).matches(/^\(\d{2}\) \d{5}-\d{4}$/, t('phone_invalid')) },
    { id: 'referralSource', label: t('referral_source'), type: 'select', options: [ { label: t('medical_referral'), value: 'medicalReferral' }, { label: t('medical_guide'), value: 'medicalGuide' }, { label: t('hospital'), value: 'hospital' }, { label: t('patient_indication'), value: 'patientIndication' }, { label: t('internet'), value: 'internet' }, { label: t('other'), value: 'other' } ], sm: 6 },
    { id: 'cep', label: t('cep'), type: 'cep', sm: 6, validation: yup.string().required(`${t('cep')} ${t('is_required')}`).matches(/^\d{5}-\d{3}$/, t('cep_invalid')), onChange: (value, setValue) => handleCepChange(value, setValue) },
    { id: 'line1', label: t('line1'), type: 'text', sm: 6, validation: yup.string().required(`${t('line1')} ${t('is_required')}`) },
    { id: 'number', label: t('number'), type: 'text', sm: 6, validation: yup.string().required(`${t('number')} ${t('is_required')}`) },
    { id: 'line2', label: t('line2'), type: 'text', sm: 6 },
    { id: 'city', label: t('city'), type: 'text', sm: 6, validation: yup.string().required(`${t('city')} ${t('is_required')}`), readOnly: true },
    { id: 'state', label: t('state'), type: 'text', sm: 6, validation: yup.string().required(`${t('state')} ${t('is_required')}`), readOnly: true },
    { id: 'country', label: t('country'), type: 'text', sm: 6, validation: yup.string().required(`${t('country')} ${t('is_required')}`), readOnly: true }
  ];

  return (
    <CustomForm
      open={open}
      onClose={onClose}
      title={patient ? t('edit_patient') : t('add_patient')}
      fields={fields}
      data={patient}
      onSubmit={handleSubmit}
    />
  );
};

export default PatientForm;
