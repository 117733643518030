import React, { useState, useEffect } from 'react';
import {
  Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, IconButton, Grid, Divider, Typography, Box, Card, CardContent, Select, MenuItem
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import {
  Add as AddIcon, Delete as DeleteIcon, DragIndicator as DragIndicatorIcon
} from '@mui/icons-material';
import api from '../../../services/api';
import { DragDropContext, Droppable, Draggable, DropResult } from 'react-beautiful-dnd';

interface OptionDto {
  label: string;
}

interface CreateClinicalStepDto {
  title: string;
  type: string;
  order: number;
  options?: OptionDto[];
}

interface CreateAppointmentModelDto {
  name: string;
  clinicalSteps?: CreateClinicalStepDto[];
}

interface UpdateAppointmentModelDto extends CreateAppointmentModelDto {
  id: number;
}

interface AppointmentModelFormProps {
  open: boolean;
  onClose: (updated?: boolean) => void;
  model?: CreateAppointmentModelDto & { id?: number } | UpdateAppointmentModelDto | null;
}

const AppointmentModelForm: React.FC<AppointmentModelFormProps> = ({ open, onClose, model }) => {
  const { t } = useTranslation();
  const [formData, setFormData] = useState<CreateAppointmentModelDto>({ name: '', clinicalSteps: [] });
  const [steps, setSteps] = useState<CreateClinicalStepDto[]>([]);

  useEffect(() => {
    if (model) {
      setFormData(model);
      setSteps(model.clinicalSteps || []);
    } else {
      setFormData({ name: '', clinicalSteps: [] });
      setSteps([]);
    }
  }, [model, open]);

  const handleFormChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleAddStep = () => {
    setSteps([...steps, { title: '', type: 'text', order: steps.length + 1, options: [] }]);
  };

  const handleRemoveStep = (index: number) => {
    setSteps(steps.filter((_, i) => i !== index).map((step, i) => ({ ...step, order: i + 1 })));
  };

  const handleStepChange = (index: number, field: keyof CreateClinicalStepDto, value: any) => {
    const updatedSteps = [...steps];
    updatedSteps[index] = { ...updatedSteps[index], [field]: value };
    
    if (field === 'type' && value !== 'select' && value !== 'multiselect') {
      updatedSteps[index].options = [];
    }

    setSteps(updatedSteps);
  };

  const handleAddOption = (index: number) => {
    const updatedSteps = [...steps];
    updatedSteps[index].options = [...(updatedSteps[index].options || []), { label: '' }];
    setSteps(updatedSteps);
  };

  const handleOptionChange = (stepIndex: number, optionIndex: number, value: string) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].options![optionIndex].label = value;
    setSteps(updatedSteps);
  };

  const handleRemoveOption = (stepIndex: number, optionIndex: number) => {
    const updatedSteps = [...steps];
    updatedSteps[stepIndex].options = updatedSteps[stepIndex].options?.filter((_, i) => i !== optionIndex);
    setSteps(updatedSteps);
  };

  const handleDragEnd = (result: DropResult) => {
    if (!result.destination) return;

    const reorderedSteps = Array.from(steps);
    const [removed] = reorderedSteps.splice(result.source.index, 1);
    reorderedSteps.splice(result.destination.index, 0, removed);

    setSteps(reorderedSteps.map((step, index) => ({ ...step, order: index + 1 })));
  };

  const handleSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    const submitData = { ...formData, clinicalSteps: steps };
    try {
      if (model && model.id) {
        await api.put(`/api/appointment-models/${model.id}`, submitData);
      } else {
        await api.post('/api/appointment-models', submitData);
      }
      onClose(true);
    } catch (error) {
      console.error('Error saving data:', error);
      onClose(false);
    }
  };

  return (
    <Dialog open={open} onClose={() => onClose(false)} maxWidth="md" fullWidth>
      <DialogTitle>{model ? t('edit_model') : t('add_model')}</DialogTitle>
      <Divider />
      <DialogContent>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <TextField
                fullWidth
                name="name"
                label={t('name')}
                value={formData.name}
                onChange={handleFormChange}
                required
                variant="outlined"
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h6">{t('steps')}</Typography>
              <div style={{ maxHeight: 'calc(50vh - 5px)', overflowY: 'auto', paddingRight: '16px', marginBottom: '10px' }}>
                <DragDropContext onDragEnd={handleDragEnd}>
                  <Droppable droppableId="steps">
                    {(provided) => (
                      <div ref={provided.innerRef} {...provided.droppableProps}>
                        {steps.map((step, index) => (
                          <Draggable key={index} draggableId={`step-${index}`} index={index}>
                            {(provided) => (
                              <Card
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                style={{ marginBottom: '1em', ...provided.draggableProps.style }}
                                elevation={2}
                              >
                                <CardContent>
                                  <Grid container spacing={2} alignItems="center">
                                    <Grid item xs={12} sm={1}>
                                      <DragIndicatorIcon style={{ cursor: 'grab' }} />
                                    </Grid>
                                    <Grid item xs={12} sm={7}>
                                      <TextField
                                        fullWidth
                                        label={t('title')}
                                        value={step.title}
                                        onChange={(e) => handleStepChange(index, 'title', e.target.value)}
                                        required
                                        variant="outlined"
                                      />
                                    </Grid>
                                    <Grid item xs={12} sm={3}>
                                      <TextField
                                        fullWidth
                                        select
                                        label={t('type')}
                                        value={step.type}
                                        onChange={(e) => handleStepChange(index, 'type', e.target.value)}
                                        required
                                        variant="outlined"
                                      >
                                        <MenuItem value="text">{t('text')}</MenuItem>
                                        <MenuItem value="select">{t('select')}</MenuItem>
                                        <MenuItem value="multiselect">{t('multiselect')}</MenuItem>
                                      </TextField>
                                    </Grid>
                                    <Grid item xs={12} sm={1}>
                                      <IconButton onClick={() => handleRemoveStep(index)} color="secondary">
                                        <DeleteIcon />
                                      </IconButton>
                                    </Grid>
                                  </Grid>
                                  {(step.type === 'select' || step.type === 'multiselect') && (
                                    <Box mt={2} pl={4}>
                                      <Typography variant="subtitle1" color="textSecondary">{t('options')}</Typography>
                                      {step.options?.map((option, optionIndex) => (
                                        <Grid container spacing={1} alignItems="center" key={optionIndex}>
                                          <Grid item xs={10}>
                                            <TextField
                                              fullWidth
                                              label={t('option')}
                                              value={option.label}
                                              onChange={(e) => handleOptionChange(index, optionIndex, e.target.value)}
                                              variant="outlined"
                                              size="small"
                                              style={{ marginTop: '8px' }}
                                            />
                                          </Grid>
                                          <Grid item xs={2}>
                                            <IconButton onClick={() => handleRemoveOption(index, optionIndex)} size="small">
                                              <DeleteIcon fontSize="small" />
                                            </IconButton>
                                          </Grid>
                                        </Grid>
                                      ))}
                                      <Button
                                        onClick={() => handleAddOption(index)}
                                        startIcon={<AddIcon />}
                                        size="small"
                                        style={{ marginTop: '8px' }}
                                      >
                                        {t('add_option')}
                                      </Button>
                                    </Box>
                                  )}
                                </CardContent>
                              </Card>
                            )}
                          </Draggable>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
              <Button variant="contained" startIcon={<AddIcon />} onClick={handleAddStep} style={{ marginTop: '1em' }} color="primary">
                {t('add_step')}
              </Button>
            </Grid>
          </Grid>
          <DialogActions>
            <Button onClick={() => onClose(false)}>{t('cancel')}</Button>
            <Button type="submit" color="primary" variant="contained">
              {model ? t('save_changes') : t('save')}
            </Button>
          </DialogActions>
        </form>
      </DialogContent>
    </Dialog>
  );
};

export default AppointmentModelForm;
