import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  IconButton,
  Box,
  Tooltip,
  TextField,
  InputAdornment,
  Button,
  Tabs,
  Tab,
  TablePagination,
} from '@mui/material';
import { Add, Edit, Search, FilterList, Delete } from '@mui/icons-material';
import { useTheme } from '@mui/material/styles';
import { useTranslation } from 'react-i18next';
import CustomPage from './CustomPage';

interface Column {
  id: string;
  label: string;
  minWidth?: number;
  align?: 'right' | 'left' | 'center';
  renderSlot?: (item: any) => React.ReactNode;
  isTabFilter?: boolean;
}

interface CustomTableProps {
  title: string;
  columns: Column[];
  data: any[];
  onAdd: () => void;
  onEdit: (item: any) => void;
  onDelete: (item: any) => void;
  filterComponent?: React.ReactNode;
  searchPlaceholder?: string;
  customActions?: (item: any) => React.ReactNode;
}

const CustomTable: React.FC<CustomTableProps> = ({
  title,
  columns,
  data,
  onAdd,
  onEdit,
  onDelete,
  filterComponent,
  searchPlaceholder = 'Pesquisar...',
  customActions,
}) => {
  const theme = useTheme();
  const { t } = useTranslation();
  const [tabValue, setTabValue] = React.useState(0);
  const [searchQuery, setSearchQuery] = React.useState('');
  const [filteredData, setFilteredData] = React.useState(data);
  const [tabs, setTabs] = React.useState<string[]>([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(7);

  React.useEffect(() => {
    generateTabs();
  }, [data]);

  React.useEffect(() => {
    applyFilters();
  }, [tabValue, searchQuery, data]);

  const generateTabs = () => {
    const tabColumn = columns.find((col) => col.isTabFilter);
    if (tabColumn) {
      const tabCounts: { [key: string]: number } = {};

      data.forEach((item) => {
        const key = item[tabColumn.id];
        if (key) {
          tabCounts[key] = (tabCounts[key] || 0) + 1;
        }
      });

      const sortedTabs = Object.keys(tabCounts).sort((a, b) => tabCounts[b] - tabCounts[a]);
      const topTabs = sortedTabs.slice(0, 8);
      const additionalTabs = sortedTabs.slice(5);

      if (additionalTabs.length > 0) {
        setTabs(['All', ...topTabs, 'Others']);
      } else {
        setTabs(['All', ...topTabs]);
      }
    }
  };

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setTabValue(newValue);
    setPage(0); // Reseta a página ao mudar de aba
  };

  const applyFilters = () => {
    let filtered = data;
    const tabColumn = columns.find((col) => col.isTabFilter);

    if (tabColumn) {
      if (tabValue === 0) {
        filtered = data;
      } else if (tabValue === tabs.length - 1 && tabs.includes('Others')) {
        const topTabs = tabs.slice(1, -1);
        filtered = filtered.filter(
          (item) => !topTabs.includes(item[tabColumn.id])
        );
      } else {
        filtered = filtered.filter((item) => item[tabColumn.id] === tabs[tabValue]);
      }
    }

    if (searchQuery) {
      filtered = filtered.filter((item) =>
        columns.some((column) =>
          String(item[column.id]).toLowerCase().includes(searchQuery.toLowerCase())
        )
      );
    }

    setFilteredData(filtered);
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  };

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const renderTabs = () => (
    <Tabs value={tabValue} onChange={handleTabChange} sx={{ borderBottom: 1, borderColor: 'divider' }}>
      {tabs.map((tab, index) => (
        <Tab key={index} label={capitalizeFirstLetter(tab)} />
      ))}
    </Tabs>
  );

  return (
    <CustomPage
      title={title}
      breadcrumbLinks={[{ label: t('dashboard'), href: '/dashboard' }]}
      actionButtons={
        <Button sx={{ borderRadius: 2 }} variant="contained" color="primary" onClick={onAdd} startIcon={<Add />}>
          {t('add')}
        </Button>
      }
    >
      {tabs.length > 0 && renderTabs()}
      <Box sx={{ display: 'flex', alignItems: 'center', mt: 2, mb: 2 }}>
        <TextField
          variant="outlined"
          placeholder={searchPlaceholder}
          size="small"
          fullWidth
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <Search />
              </InputAdornment>
            ),
          }}
          sx={{
            '& .MuiOutlinedInput-root': {
              '& fieldset': {
                borderColor: theme.palette.divider,
              },
            },
          }}
        />
        {filterComponent && (
          <Box sx={{ ml: 2 }}>
            <IconButton>
              <FilterList />
            </IconButton>
            {filterComponent}
          </Box>
        )}
      </Box>
      <TableContainer>
        <Table>
          <TableHead sx={{ backgroundColor: theme.palette.tableHeader }}>
            <TableRow>
              {columns.map((column) => (
                <TableCell
                  key={column.id}
                  align={column.align}
                  style={{ minWidth: column.minWidth }}
                >
                  {column.label}
                </TableCell>
              ))}
              <TableCell align="center" sx={{ width: '150px' }}>{t('actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {filteredData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row) => (
              <TableRow key={row.id} sx={{ '&:hover': { backgroundColor: theme.palette.action.hover }, cursor: 'pointer' }}>
                {columns.map((column) => (
                  <TableCell key={column.id} align={column.align}>
                    {column.renderSlot ? column.renderSlot(row) : row[column.id]}
                  </TableCell>
                ))}
                <TableCell align="center" sx={{ width: '150px', whiteSpace: 'nowrap' }}>
                  {customActions && customActions(row)}
                  <Tooltip title={t('edit')}>
                    <IconButton onClick={() => onEdit(row)}>
                      <Edit color="warning" />
                    </IconButton>
                  </Tooltip>
                  <Tooltip title={t('delete')}>
                    <IconButton onClick={() => onDelete(row)}>
                      <Delete color="error" />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={filteredData.length}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
        rowsPerPageOptions={[7]}
      />
    </CustomPage>
  );
};

export default CustomTable;
